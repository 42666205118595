<template>
  <div class="header sticky-top">
    <HeaderSectionCmp v-bind:items="items" v-bind:saihanFlg="saihanFlg" v-bind:maintenanceFlg="maintenanceFlg" />
    <MaintenanceSectionMenuCmp />
  </div>
  <MaintenanceSectionCmp name="contents" v-bind:items="items" v-bind:mngMessage="mngMessage" @StartLoding="StartLoding" @EndLoding="EndLoding"/>
</template>

<script>

import HeaderSectionCmp from '../components/HeaderSectionCmp.vue'
import MaintenanceSectionMenuCmp from '../components/MaintenanceSectionMenuCmp.vue'
import MaintenanceSectionCmp from '../components/MaintenanceSectionCmp.vue'

export default {
  name: 'MaintenanceSection',
  components: {
    HeaderSectionCmp,
    MaintenanceSectionMenuCmp,
    MaintenanceSectionCmp
  },
  props: {
    saihanFlg: Boolean,
    maintenanceFlg: Boolean,
    mngMessage: String
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    
  }
}
</script>
