<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-clearfix u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-1">
                <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
                  <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-container-layout-2">
                    <h6 class="u-text u-text-default u-text-1">契約情報</h6>
                  </div>
                </div>
                <div class="u-container-style u-group u-shape-rectangle u-group-2">
                  <div class="u-container-layout u-container-layout-3">
                    <div class="planTable u-table u-table-responsive u-table-1">
                      <perfect-scrollbar>
                      <table class="u-table-entity" style="border-collapse: separate; border-spacing: 0px 6px;">
                        <colgroup>
                          <col width="5.98%">
                          <col width="14.96%">
                          <col width="56.91%">
                          <col width="22.15%">
                        </colgroup>
                        <tbody v-for="list in lists" :key="list" class="u-table-body">
                          <tr v-if="list.tenantoKeiyakuTagCls" style="height: 26px;">
                            <td style="border: none;" colspan="4" class="fixed u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-table-cell-1">{{ list.nonyusakimei }}</td>
                          </tr>
                          <tr v-if="list.nonyusakiTagCls" style="height: 26px; white-space: nowrap;">
                            <td style="border: none;" colspan="4" class="fixed u-table-cell-hover u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-table-cell-1" v-on:click="toNonyuDetail(list.nonyusaki_index)">{{ list.nonyusakimei }}</td>
                          </tr>
                          <tr v-if="list.devicemeiTagCls" style="height: 26px;">
                            <td style="border: none;" class="u-border-1 u-border-grey-10 u-border-no-left u-border-no-right u-table-cell u-table-cell-5"></td>
                            <td style="border-left: 1px solid; border-color: #f2f2f2;" colspan="3" class="u-border-1 u-border-grey-10 u-border-no-left u-border-no-right u-custom-color-23 u-table-cell u-text-grey-75 u-table-cell-6">{{ list.devicemei }}</td>
                          </tr>
                          <tr v-if="list.headerCls" style="height: 32px;">
                            <td style="border: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-9"></td>
                            <td style="border: none;" class="headercls u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-10">{{ list.service_kubunmei }}</td>
                            <td style="border: none;" class="headercls u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-11">{{ list.servicemei }}</td>
                            <td style="border: none;" class="headercls u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-12">{{ list.riyo_kaishibi }}</td>
                          </tr>
                          <tr v-if="(list.dataCls && list.service_kubun==this.service_kubun_tenant_plan) || (list.dataCls && list.service_kubun==this.service_kubun_device_plan)" style="height: 25px;">
                            <td style="border: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-white u-table-cell-13"></td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-palette-1-base u-table-cell u-text-grey-5 u-table-cell-14">{{ list.service_kubunmei }}</td>
                            <td style="border-top: none;" class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-table-cell-15">{{ list.servicemei }}</td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-16">{{ filterYYYYMMDD(list.riyo_kaishibi) }}</td>
                          </tr>
                          <tr v-if="(list.dataCls && list.service_kubun==this.service_kubun_tenant_option) || (list.dataCls && list.service_kubun==this.service_kubun_device_option)" style="height: 28px;">
                            <td style="border: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-white u-table-cell-17"></td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-palette-4-base u-table-cell u-text-white u-table-cell-18">{{ list.service_kubunmei }}</td>
                            <td style="border-top: none;" class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-table-cell-19">{{ list.servicemei }}</td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-20">{{ filterYYYYMMDD(list.riyo_kaishibi) }}</td>
                          </tr>
                          <tr v-if="(list.dataCls && list.service_kubun==this.service_kubun_tenant_campaign) || (list.dataCls && list.service_kubun==this.service_kubun_device_campaign)" style="height: 28px;">
                            <td style="border: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-white u-table-cell-21"></td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-color-22 u-custom-font u-table-cell u-text-grey-70 u-table-cell-22">{{ list.service_kubunmei }}</td>
                            <td style="border-top: none;" class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-table-cell-23">{{ list.servicemei }}</td>
                            <td style="border-top: none;" class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-24">{{ filterYYYYMMDD(list.riyo_kaishibi) }}</td>
                          </tr>
                          <!-- <tr style="height: 42px;">
                            <td class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-white u-table-cell-25"></td>
                            <td class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-grey-30 u-table-cell u-text-body-alt-color u-table-cell-26">休止</td>
                            <td class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-grey-40 u-table-cell-27">初月無料</td>
                            <td class="u-align-center u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-28"> 2022年09月28日まで休止</td>
                          </tr> -->
                        </tbody>
                      </table>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment"

export default {
  name: 'UsageStatusSectionPlanCmp',
  props: {items: Object},
  data(){
    return{
      service_kubun_tenant_plan: "1",
      service_kubun_tenant_option: "2",
      service_kubun_device_plan: "3",
      service_kubun_device_option: "4",
      service_kubun_hoshu_plan: "5",
      service_kubun_hoshu_option: "6",
      service_kubun_tenant_campaign: "7",
      service_kubun_device_campaign: "8",
      list_format: {},
      lists: [
        { 
          id: 0,
          nonyusaki_index: 0,
          nonyusaki_code: "　",
          nonyusakimei: "　",
          nonyusaki_seq: "　",
          service_no: "　",
          service_seq: "　",
          servicemei: "　",
          service_kubun: "　",
          service_kubunmei: "　",
          riyo_kaishibi: "　",
          device_no: "　",
          devicemei: "　",
          tenantoKeiyakuTagCls: false,
          nonyusakiTagCls: false,
          devicemeiTagCls: false,
          headerCls: false,
          dataCls: false
        }
      ]
    }
  },
  watch: {
    items: function() {
      this.setData();
    }
  },
  created(){
    if(this.items != null){
      this.setData();
    }    
  },
  methods: {
    setData(){
      this.lists.length = 0 //配列初期化
      var bkNonyusakiSeq = "99999999";
      var bkDeviceNo = "99999999";
      var nonyusaki_index = 0;
      for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
        if(bkNonyusakiSeq != this.items.plan_option_list[idx].nonyusaki_seq.value && this.items.plan_option_list[idx].device_no.value >= 0){
          //納入先タグ挿入
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.nonyusaki_index = nonyusaki_index;
          this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
          if(this.items.plan_option_list[idx].nonyusaki_seq.value == 0){
            this.list_format.nonyusaki_code = "　";
            this.list_format.nonyusakimei = "契約オプション";
          } else {
            this.list_format.nonyusaki_code = this.items.plan_option_list[idx].nonyusaki_code.value;
            this.list_format.nonyusakimei = this.items.plan_option_list[idx].nonyusakimei.value;
          }
          this.list_format.service_no = "　";
          this.list_format.service_seq = "　";
          this.list_format.servicemei = "　";
          this.list_format.service_kubun = "　";
          this.list_format.service_kubunmei = "　";
          this.list_format.riyo_kaishibi = "　";
          this.list_format.device_no = "　";
          this.list_format.devicemei = "　";
          if(this.items.plan_option_list[idx].nonyusaki_seq.value == 0){
            this.list_format.tenantoKeiyakuTagCls = true;
            this.list_format.nonyusakiTagCls = false;
          } else {
            this.list_format.tenantoKeiyakuTagCls = false;
            this.list_format.nonyusakiTagCls = true;
          }
          this.list_format.devicemeiTagCls = false;
          this.list_format.headerCls = false;
          this.list_format.dataCls = false;
          if(this.checkNonyusakiBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value) == true){
            this.lists.push(this.list_format) //データPUSH
          }

          if(this.items.plan_option_list[idx].nonyusaki_seq.value == 0){
            //ヘッダ部挿入
            this.list_format = {};
            this.list_format.id = -1;
            this.list_format.nonyusaki_index = nonyusaki_index;
            this.list_format.nonyusaki_code = this.items.plan_option_list[idx].nonyusaki_code.value;
            this.list_format.nonyusakimei = this.items.plan_option_list[idx].nonyusakimei.value;
            this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
            this.list_format.service_no = "　";
            this.list_format.service_seq = "　";
            this.list_format.servicemei = "サービス名";
            this.list_format.service_kubun = "　";
            this.list_format.service_kubunmei = "種別";
            this.list_format.riyo_kaishibi = "利用開始日";
            this.list_format.device_no = "　";
            this.list_format.devicemei = "デバイス名";
            this.list_format.tenantoKeiyakuTagCls = false;
            this.list_format.nonyusakiTagCls = false;
            this.list_format.devicemeiTagCls = false;
            this.list_format.headerCls = true;
            this.list_format.dataCls = false;
            if(this.checkNonyusakiBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value) == true){
              this.lists.push(this.list_format) //データPUSH
            }
          }
          
          bkNonyusakiSeq = this.items.plan_option_list[idx].nonyusaki_seq.value
          if(this.items.plan_option_list[idx].nonyusaki_seq.value != 0){
            if(this.checkNonyusakiBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value) == true){
              nonyusaki_index++;
            }
          }  
        }

        if(bkDeviceNo != this.items.plan_option_list[idx].device_no.value && this.items.plan_option_list[idx].nonyusaki_seq.value != 0 && this.items.plan_option_list[idx].device_no.value >= 0){
          //デバイス名タグ挿入
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.nonyusaki_index = nonyusaki_index;
          this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
          this.list_format.nonyusaki_code = "　";
          this.list_format.nonyusakimei = "　";
          this.list_format.service_no = "　";
          this.list_format.service_seq = "　";
          this.list_format.servicemei = "　";
          this.list_format.service_kubun = "　";
          this.list_format.service_kubunmei = "　";
          this.list_format.riyo_kaishibi = "　";
          this.list_format.device_no = this.items.plan_option_list[idx].device_no.value;
          this.list_format.devicemei = "デバイス名 ： " + this.items.plan_option_list[idx].devicemei.value;
          this.list_format.tenantoKeiyakuTagCls = false;
          this.list_format.nonyusakiTagCls = false;
          this.list_format.devicemeiTagCls = true;
          this.list_format.headerCls = false;
          this.list_format.dataCls = false;
          if(this.checkDeviceBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value, this.items.plan_option_list[idx].device_no.value) == true){
            this.lists.push(this.list_format) //データPUSH
          }

          //ヘッダ部挿入
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.nonyusaki_index = nonyusaki_index;
          this.list_format.nonyusaki_code = this.items.plan_option_list[idx].nonyusaki_code.value;
          this.list_format.nonyusakimei = this.items.plan_option_list[idx].nonyusakimei.value;
          this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
          this.list_format.service_no = "　";
          this.list_format.service_seq = "　";
          this.list_format.servicemei = "サービス名";
          this.list_format.service_kubun = "　";
          this.list_format.service_kubunmei = "種別";
          this.list_format.riyo_kaishibi = "利用開始日";
          this.list_format.device_no = "　";
          this.list_format.devicemei = "デバイス名";
          this.list_format.tenantoKeiyakuTagCls = false;
          this.list_format.nonyusakiTagCls = false;
          this.list_format.devicemeiTagCls = false;
          this.list_format.headerCls = true;
          this.list_format.dataCls = false;
          if(this.checkDeviceBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value, this.items.plan_option_list[idx].device_no.value) == true){
            this.lists.push(this.list_format) //データPUSH
          }
          bkDeviceNo = this.items.plan_option_list[idx].device_no.value
        }

        if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
          var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
          var dateNow = moment()
          if(dateNow.diff(dateEnd, 'days') > 0){
            //利用期間を超過したものはスキップ
            continue;
          }
        }

        //実データ部挿入
        this.list_format = {};
        this.list_format.id = idx;
        this.list_format.nonyusaki_index = nonyusaki_index;
        this.list_format.nonyusaki_code = this.items.plan_option_list[idx].nonyusaki_code.value;
        this.list_format.nonyusakimei = this.items.plan_option_list[idx].nonyusakimei.value;
        this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
        this.list_format.service_no = this.items.plan_option_list[idx].service_no.value;
        this.list_format.service_seq = this.items.plan_option_list[idx].service_seq.value;
        if(this.items.plan_option_list[idx].suryo.value > 1){
          this.list_format.servicemei = this.items.plan_option_list[idx].servicemei.value + " × " + this.items.plan_option_list[idx].suryo.value;
        } else {
          this.list_format.servicemei = this.items.plan_option_list[idx].servicemei.value;
        }
        this.list_format.service_kubun = this.items.plan_option_list[idx].service_kubun.value;
        this.list_format.service_kubunmei = this.items.plan_option_list[idx].service_kubunmei.value;
        this.list_format.riyo_kaishibi = this.items.plan_option_list[idx].riyo_kaishibi.value;
        this.list_format.device_no = this.items.plan_option_list[idx].device_no.value;
        this.list_format.devicemei = this.items.plan_option_list[idx].devicemei.value;
        this.list_format.tenantoKeiyakuTagCls = false;
        this.list_format.nonyusakiTagCls = false;
        this.list_format.devicemeiTagCls = false;
        this.list_format.headerCls = false;
        this.list_format.dataCls = true;
        this.lists.push(this.list_format) //データPUSH
      }
    },
    filterYYYYMMDD: function(yyyymmdd){
      var formatDate = moment(yyyymmdd).format("YYYY年MM月DD日");
      if(formatDate == "Invalid date"){
        formatDate = "　"
      }
      return formatDate;
    },
    toNonyuDetail: function(nonyusaki_index){
      this.$router.push({name: 'Nonyu', query: { index: nonyusaki_index}});
    },
    checkDeviceBranchEnabled: function(nonyusaki_seq, device_no){
      var enabledServiceCount = 0
      for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
        if(this.items.plan_option_list[idx].nonyusaki_seq.value == nonyusaki_seq && this.items.plan_option_list[idx].device_no.value == device_no){
          if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
            var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
            var dateNow = moment()
            if(dateEnd.diff(dateNow, 'days') >= 0){
              //alert("diff")
              //利用期間中のサービスをカウント
              enabledServiceCount = enabledServiceCount + 1
            }
          } else {
            enabledServiceCount = enabledServiceCount + 1
          }
        }
      }
      if(enabledServiceCount > 0){
        return true
      } else {
        return false
      }
    },
    checkNonyusakiBranchEnabled: function(nonyusaki_seq){
      var enabledServiceCount = 0
      for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
        if(this.items.plan_option_list[idx].nonyusaki_seq.value == nonyusaki_seq){
          if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
            var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
            var dateNow = moment()
            if(dateEnd.diff(dateNow, 'days') >= 0){
              //alert("diff")
              //利用期間中のサービスをカウント
              enabledServiceCount = enabledServiceCount + 1
            }
          } else {
            enabledServiceCount = enabledServiceCount + 1
          }
        }
      }
      if(enabledServiceCount > 0){
        return true
      } else {
        return false
      }
    },
    addDummy(){
        //実データ部挿入
        this.list_format = {};
        this.list_format.id = -999;
        this.list_format.nonyusaki_code = "1Z999999";
        this.list_format.nonyusakimei = "dummy";
        this.list_format.nonyusaki_seq = "dummy";
        this.list_format.service_no = "dummy";
        this.list_format.service_seq = "dummy";
        this.list_format.servicemei = "dummy";
        this.list_format.service_kubun = "1";
        this.list_format.service_kubunmei = "dummy";
        this.list_format.riyo_kaishibi = "dummy";
        this.list_format.device_no = "dummy";
        this.list_format.devicemei = "dummy";
        this.list_format.nonyusakiTagCls = false;
        this.list_format.headerCls = false;
        this.list_format.dataCls = true;
        this.lists.push(this.list_format)
    },
    goToTanto() {
      this.$router.push('/userInfo/tanto');
    },
    goToSeikyuTanto() {
      this.$router.push('/userInfo/seikyu');
    },
    myMethod() {
      alert("UsageStatusSection()");
    },
  }
}
</script>

<style src="../css/UsageStatusSectionPlan.css" scoped>
</style>

<style scoped>

  section {
    min-height: calc(100vh - 130px);
  }

  .u-table-cell-hover:hover {
    color: rgb(22, 97, 205) !important;
    cursor: pointer;
  }

  .fixed{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: linear-gradient(.25turn, #f4f5ff, #f4f5ff 60%, rgba(0,0,0,0));
  }

  .headercls{
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0) 50%, #ecedf0 );
  }

  .ps {
    height: 450px;
  }

</style>