<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-clearfix u-expanded-width-xs u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-1">
                <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
                  <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-container-layout-2">
                    <h6 class="u-text u-text-default u-text-1">ご請求情報</h6>
                  </div>
                </div>
                <div class="u-container-style u-group u-shape-rectangle u-group-2">
                  <div class="u-container-layout u-valign-top-xs u-container-layout-3">
                    <div class="seikyuTable u-expanded-width u-table u-table-responsive u-table-1">
                      <perfect-scrollbar>
                      <table class="u-table-entity">
                        <colgroup>
                          <col width="14.47%">
                          <col width="25.66%">
                          <col width="14.74%">
                          <col width="14.47%">
                          <col width="14.47%">
                          <col width="6.88%">
                          <col width="9.31%">
                        </colgroup>
                        <tbody v-for="list in lists" :key="list" class="u-table-body">
                          <tr v-if="list.thCls" style="height: 34px;">
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-1 u-table-cell-hover" v-on:click="changeSorting()">{{ list.seikyuduki }}&nbsp;<span class="u-icon"><svg class="u-svg-content st0 rotate-init" v-bind:class="{'rotate-up': isRotateUp, 'rotate-down': isRotateDown}" viewBox="0 0 300 300" x="0px" y="0px" style="width: 1em; height: 1em;"><g><line class="st0" x1="150" y1="86.5" x2="150" y2="205.04"></line><polyline class="st0" points="99.68,168.65 150,213.5 200.32,168.65 	"></polyline></g></svg><img></span></td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-2 u-table-cell-escaped">{{ list.seikyu_naiyou }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-3 u-table-cell-escaped">{{ list.seikyu_biko }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-4 u-table-cell-escaped">{{ list.seikyu_kingakukei }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-5 u-table-cell-escaped">{{ list.seikyu_tanka }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-6 u-table-cell-escaped">{{ list.seikyu_suryo }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-1-base u-table-cell-7"></td>
                          </tr>
                          <tr v-if="list.headerCls" style="height: 50px;" v-on:dblclick="changeAppearDetal(list.seikyuduki)">
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-8">{{ filterSeikyuduki(list.seikyuduki) }}</td>
                            <td class="u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-table-cell-9">{{ list.seikyu_naiyou }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-10">-</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-11">{{ filterLocaleString(list.seikyu_kingakukei) }}</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-12">-</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-13">-</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-custom-font u-table-cell u-text-palette-4-base u-table-cell-14 u-table-cell-hover" v-on:click="changeAppearDetal(list.seikyuduki)">内訳</td>
                          </tr>
                          <transition name="slide">
                          <tr v-if="list.detailCls && list.appearFlg" style="height: 47px;">
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-table-cell u-text-grey-40 u-table-cell-15"></td>
                            <td class="u-align-left u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-16">{{ list.seikyu_servicemei }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-17">{{ list.seikyu_biko }}</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-18">{{ filterLocaleString(list.seikyu_kingaku) }}</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-19">{{ filterLocaleString(list.seikyu_tanka) }}</td>
                            <td class="u-align-right u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-20">{{ list.seikyu_suryo + " " + list.seikyu_tani }}</td>
                            <td class="u-align-center u-border-1 u-border-grey-15 u-border-no-left u-border-no-right u-palette-1-light-3 u-table-cell u-text-grey-40 u-table-cell-21"></td>
                          </tr>
                          </transition>
                        </tbody>
                      </table>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment"

export default {
  name: 'UsageStatusSectionSeikyuCmp',
  props: {items: Object},
  data(){
    return{
      isShow: false,
      isSort: false,
      isRotateUp: false,
      isRotateDown: false,
      seikyu_jisseki_list: Object,
      list_format: {},
      lists: [
        { 
          id: 0,
          seikyuduki: "　",
          seikyu_shubetsu: "　",
          seikyu_shubetsumei: "　",
          kakutei_flag: "　",
          kakutei_flagmei: "　",
          seikyu_naiyou: "　",
          seikyu_kingakukei: "　",

          seikyu_seq: "　",
          seikyu_gyo_no: "　",
          seikyu_servicemei: "　",
          seikyu_biko: "　",
          seikyu_kingaku: "　",
          seikyu_tanka: "　",
          seikyu_suryo: "　",
          seikyu_tani: "　",
          thCls: false,
          headerCls: false,
          detailCls: false,
          appearFlg: false
        }
      ]
    }
  },
  watch: {
    items: function() {
      this.seikyu_jisseki_list = this.items.seikyu_jisseki_list
      this.setData();
      this.sort([{'value': 'seikyuduki','direction': -1},{'value': 'seikyu_seq','direction': 1}])
    },
    isSort: function(){
      /* console.log("isSort:" + this.isSort) */
      if(this.isSort){
        this.enabledRotateUp();
        this.sort([{'value': 'seikyuduki','direction': 1},{'value': 'seikyu_seq','direction': 1}])
      } else {
        this.enabledRotateDown();
        this.sort([{'value': 'seikyuduki','direction': -1},{'value': 'seikyu_seq','direction': 1}])
      }
    }
  },
  created(){
    if(this.items != null){
      this.seikyu_jisseki_list = this.items.seikyu_jisseki_list
      this.setData();
      this.sort([{'value': 'seikyuduki','direction': -1},{'value': 'seikyu_seq','direction': 1}])
    }    
  },
  methods: {
    buttonDoubleclick(){
      //alert("buttonDoubleclick")
    },
    setData(){
      this.lists.length = 0 //配列初期化
      var bkSeikyuduki = "999999";
      for(var idx = 0; idx < this.seikyu_jisseki_list.length; idx++){
        if(idx == 0){
          //テーブルヘッダ部挿入
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.seikyuduki = "請求年月";
          this.list_format.seikyu_shubetsu = "　";
          this.list_format.seikyu_shubetsumei = "　";
          this.list_format.kakutei_flag = "　";
          this.list_format.kakutei_flagmei = "　";
          this.list_format.seikyu_naiyou = "摘要・\nご利用サービス";
          this.list_format.seikyu_kingakukei = "ご請求金額\n(税込み)";

          this.list_format.seikyu_seq = "　";
          this.list_format.seikyu_gyo_no = "　";
          this.list_format.seikyu_servicemei = "　";
          this.list_format.seikyu_biko = "ご利用期間";
          this.list_format.seikyu_kingaku = "　";
          this.list_format.seikyu_tanka = "単価";
          this.list_format.seikyu_suryo = "数量";
          this.list_format.seikyu_tani = "　";
          this.list_format.thCls = true;
          this.list_format.headerCls = false;
          this.list_format.detailCls = false;
          this.list_format.appearFlg = true;
          this.lists.push(this.list_format)
        }
        if(bkSeikyuduki != this.seikyu_jisseki_list[idx].seikyuduki.value){
          //データヘッダ部挿入
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.seikyuduki = this.seikyu_jisseki_list[idx].seikyuduki.value;
          this.list_format.seikyu_shubetsu = this.seikyu_jisseki_list[idx].seikyu_shubetsu.value;
          this.list_format.seikyu_shubetsumei = this.seikyu_jisseki_list[idx].seikyu_shubetsumei.value;
          this.list_format.kakutei_flag = this.seikyu_jisseki_list[idx].kakutei_flag.value;
          this.list_format.kakutei_flagmei = this.seikyu_jisseki_list[idx].kakutei_flagmei.value;
          this.list_format.seikyu_naiyou = this.seikyu_jisseki_list[idx].seikyu_naiyou.value;
          this.list_format.seikyu_kingakukei = this.seikyu_jisseki_list[idx].seikyu_kingakukei.value;

          this.list_format.seikyu_seq = this.seikyu_jisseki_list[idx].seikyu_seq.value;
          this.list_format.seikyu_gyo_no = this.seikyu_jisseki_list[idx].seikyu_gyo_no.value;
          this.list_format.seikyu_servicemei = "　";
          this.list_format.seikyu_biko = "-";
          this.list_format.seikyu_kingaku = "-";
          this.list_format.seikyu_tanka = "-";
          this.list_format.seikyu_suryo = "-";
          this.list_format.seikyu_tani = "-";
          this.list_format.thCls = false;
          this.list_format.headerCls = true;
          this.list_format.detailCls = false;
          this.list_format.appearFlg = true;
          this.lists.push(this.list_format)
          bkSeikyuduki = this.seikyu_jisseki_list[idx].seikyuduki.value
        }
        //データ明細部挿入
        this.list_format = {};
        this.list_format.id = idx;
        this.list_format.seikyuduki = this.seikyu_jisseki_list[idx].seikyuduki.value;
        this.list_format.seikyu_shubetsu = this.seikyu_jisseki_list[idx].seikyu_shubetsu.value;
        this.list_format.seikyu_shubetsumei = this.seikyu_jisseki_list[idx].seikyu_shubetsumei.value;
        this.list_format.kakutei_flag = this.seikyu_jisseki_list[idx].kakutei_flag.value;
        this.list_format.kakutei_flagmei = this.seikyu_jisseki_list[idx].kakutei_flagmei.value;
        this.list_format.seikyu_naiyou = "　";
        this.list_format.seikyu_kingakukei = "　";

        this.list_format.seikyu_seq = this.seikyu_jisseki_list[idx].seikyu_seq.value;
        this.list_format.seikyu_gyo_no = this.seikyu_jisseki_list[idx].seikyu_gyo_no.value;
        this.list_format.seikyu_servicemei = this.seikyu_jisseki_list[idx].seikyu_servicemei.value;
        this.list_format.seikyu_biko = this.seikyu_jisseki_list[idx].seikyu_biko.value;
        this.list_format.seikyu_kingaku = this.seikyu_jisseki_list[idx].seikyu_kingaku.value;
        this.list_format.seikyu_tanka = this.seikyu_jisseki_list[idx].seikyu_tanka.value;
        this.list_format.seikyu_suryo = this.seikyu_jisseki_list[idx].seikyu_suryo.value;
        this.list_format.seikyu_tani = this.seikyu_jisseki_list[idx].seikyu_tani.value;
        this.list_format.thCls = false;
        this.list_format.headerCls = false;
        this.list_format.detailCls = true;
        this.list_format.appearFlg = false;
        this.lists.push(this.list_format)
      }
      //console.log(this.lists)
    },
    changeAppearDetal: function(seikyuduki){
      for(var idx = 0; idx < this.lists.length; idx++){
        if(this.lists[idx].seikyuduki == seikyuduki && this.lists[idx].detailCls == true){
          this.lists[idx].appearFlg = !this.lists[idx].appearFlg
        }
      }
    },
    goToTanto() {
      this.$router.push('/userInfo/tanto');
    },
    goToSeikyuTanto() {
      this.$router.push('/userInfo/seikyu');
    },
    filterSeikyuduki: function(yymm){
      return moment(yymm).format("YYYY年MM月");
    },
    filterLocaleString: function(val){
      return Number(val).toLocaleString() + " 円";
    },
    basicSort: function(a, b, key, direction, nullsFirst = 1){
      if (Number(a[key].value) == undefined && Number(b[key].value) == undefined) return 0;
      if (Number(a[key].value) == undefined) return nullsFirst * 1;
      if (Number(b[key].value) == undefined) return nullsFirst * -1;
      if (Number(a[key].value) > Number(b[key].value)) return direction * 1;
      if (Number(a[key].value) < Number(b[key].value)) return direction * -1;
      return 0;
    },
    sort: function(keys){
      this.seikyu_jisseki_list.sort((a, b) => {
        let order = 0;
        keys.some(key => {
          order = this.basicSort(a, b, key.value, key.direction);
          return !!order;
        });
        return order;
      });
      this.setData();
      /* console.log(this.seikyu_jisseki_list) */
    },
    enabledRotateUp(){
      this.isRotateUp = true
      this.isRotateDown = false
    },
    enabledRotateDown(){
      this.isRotateUp = false
      this.isRotateDown = true
    },
    changeSorting(){
      this.isSort = !this.isSort
    }
  }
}
</script>

<style src="../css/UsageStatusSectionSeikyu.css" scoped>
</style>

<style scoped>

  section {
    min-height: calc(100vh - 130px);
  }

  .u-border-1.u-border-tableLeft{
    border-top: none;
    border-bottom: none;
  }

  .u-table-cell-escaped{
    white-space: pre-wrap;
  }
  
  .u-table-cell-hover:hover {
    color: rgb(240, 11, 11) !important;
    cursor: pointer;
  }

  .slide-enter-to {
  transition: transform 0.5s ease-out;
  transform: translateX(0px);
  }
  .slide-enter-from {
    transform: translateX(250px);
  }

  .slide-leave-to {
    transition: transform 0.5s ease-out;
    transform: translateX(250px);
  }
  .slide-leave-from {
    transform: translateX(0px);
  }

  .ps {
    height: 450px;
  }

  .rotate-init {
    display: inline-block;
    transform: rotate(0deg);
  }

  .rotate-up {
    display: inline-block;
    transform: rotate(0deg);
    animation: rotate-up 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }

  .rotate-down {
    display: inline-block;
    transform: rotate(180deg);
    animation: rotate-down 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes rotate-up {
    100%{ transform: rotate(180deg) }
  }

  @keyframes rotate-down {
    100%{ transform: rotate(0deg) }
  }

  .st0{
    fill:none;
    stroke:#323333;
    stroke-width:8;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
  }

</style>