<template>
  <section class="u-clearfix u-white u-section-1" id="menuSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-align-center u-table u-table-responsive u-table-1">
        <table class="u-table-entity">
          <colgroup>
            <col width="41.38%">
            <col width="17.24%">
            <col width="41.38%">
          </colgroup>
          <tbody class="u-table-body">
            <tr style="height: 48px;">
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-1"><router-link to="/userInfo/tanto" exact class="router-link-passive" active-class="router-link-active">契約者情報</router-link></td>
              <td class="u-table-cell"></td>
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-3"><router-link :to="{ name: 'Nonyu', query: { index: 0 }}" exact class="router-link-passive" active-class="router-link-active">納入先情報</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserInfoSectionMenuSaihanCmp'
}
</script>

<style src="../css/UserInfoSectionSaihan.css" scoped>
</style>