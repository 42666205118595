<template>
  <section class="u-clearfix u-white u-section-1" id="menuSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-align-center u-expanded-width-xs u-table u-table-responsive u-table-1">
        <table class="u-table-entity">
          <colgroup>
            <col width="26.09%">
            <col width="10.87%">
            <col width="26.09%">
            <col width="10.87%">
            <col width="26.09%">
          </colgroup>
          <tbody class="u-table-body">
            <tr style="height: 48px;">
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-1"><router-link to="/userInfo/tanto" exact class="router-link-passive" active-class="router-link-active">契約者情報</router-link></td>
              <td class="u-table-cell"></td>
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-3"><router-link to="/userInfo/seikyu" exact class="router-link-passive" active-class="router-link-active">請求先情報</router-link></td>
              <td class="u-table-cell u-table-cell-4"></td>
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-5"><router-link :to="{ name: 'Nonyu', query: { index: 0 }}" exact class="router-link-passive" active-class="router-link-active">納入先情報</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserInfoSectionMenuCmp'
}
</script>

<style src="../css/UserInfoSection.css" scoped>
</style>