<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-clearfix u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-valign-bottom-lg u-valign-bottom-md u-valign-bottom-xl u-valign-bottom-xxl u-container-layout-1">
                <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-group u-group-1">
                  <div class="u-container-layout u-valign-middle u-container-layout-2">
                    <h6 class="u-text u-text-default u-text-1">契約者情報</h6>
                  </div>
                </div>
                <div class="u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-shape-rectangle u-group-2">
                  <div class="u-container-layout u-container-layout-3">
                    <div class="u-expanded-width-xs u-table u-table-responsive u-table-1">
                      <perfect-scrollbar>
                      <table class="u-table-entity">
                        <colgroup>
                          <col width="29.99%">
                          <col width="70.01%">
                        </colgroup>
                        <tbody class="u-table-body">
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-valign-middle u-table-cell-1">契約番号</td>
                            <td class="u-table-cell u-table-cell-2">{{ this.keiyaku_no }}</td>
                          </tr>
                          <tr style="height: 26px; white-space: nowrap;">
                            <td class="u-table-cell u-table-valign-bottom u-table-cell-3"></td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-valign-bottom u-table-cell-4">{{ this.keiyakusakimei_furigana }}<span style="font-size: 0.625rem; "></span>
                            </td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-valign-top u-table-cell-5">会社・団体名</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-valign-top u-table-cell-6">{{ this.keiyakusakimei }}</td>
                          </tr>
                          <tr style="height: 20px;">
                            <td class="u-table-cell u-table-valign-bottom u-table-cell-7"></td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-valign-bottom u-table-cell-8">{{ this.keiyakusyatanto_furigana }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-valign-top u-table-cell-9">契約担当者氏名</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-valign-top u-table-cell-10">{{ this.keiyakusya_tantoshamei }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-valign-middle u-table-cell-11">部署名</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-12">{{ this.keiyakusya_bushomei }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-valign-middle u-table-cell-13">電話番号</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-14">{{ this.keiyakusaki_denwabango }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-cell-15">メールアドレス</td>
                            <td style="word-break: break-all;" class="u-align-left-xs u-table-cell u-table-cell-16">{{ this.keiyakusaki_tantosha_email }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-cell-17">郵便番号</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-18">{{ this.keiyakusaki_yubinbango }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-cell-19">都道府県</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-20">{{ this.keiyakusaki_todofuken }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-cell-21">市区群町村</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-22">{{ this.keiyakusaki_shikugunchoson }}</td>
                          </tr>
                          <tr style="height: 40px;">
                            <td class="u-table-cell u-table-cell-23">番地・ビル名</td>
                            <td style="word-break: break-all;" class="u-table-cell u-table-cell-24">{{ this.keiyakusaki_banchibuilding }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserInfoSectionTantoCmp',
  props: {items: Object},
  data(){
    return{
      blank : "　",
      keiyaku_no: this.blank,
      keiyakusakimei_furigana: this.blank,
      keiyakusakimei: this.blank,
      keiyakusyatanto_furigana: this.blank,
      keiyakusya_tantoshamei: this.blank,
      keiyakusya_bushomei: this.blank,
      keiyakusaki_denwabango: this.blank,
      keiyakusaki_tantosha_email: this.blank,
      keiyakusaki_yubinbango: this.blank,
      keiyakusaki_todofuken: this.blank,
      keiyakusaki_shikugunchoson: this.blank,
      keiyakusaki_banchibuilding: this.blank,
    }
  },
  watch: {
    items: function() {
      this.setData();
    }
  },
  created(){
    if(this.items != null){
      this.setData();
    }    
  },
  methods: {
    getValue : function(item){
      return item == undefined || item.length == 0 ? this.blank : item;
    },
    setData(){
      try {
        this.keiyaku_no = this.items.keiyaku_no;
        this.keiyakusakimei_furigana = this.getValue(this.items.keiyakusakimei_furigana?.value);
        this.keiyakusakimei = this.getValue(this.items.keiyakusakimei?.value);
        this.keiyakusyatanto_furigana = this.getValue(this.items.keiyakusyatanto_furigana?.value);
        this.keiyakusya_tantoshamei = this.getValue(this.items.keiyakusya_tantoshamei?.value);
        this.keiyakusya_bushomei = this.getValue(this.items.keiyakusya_bushomei?.value);
        this.keiyakusaki_denwabango = this.getValue(this.items.keiyakusaki_denwabango?.value);
        this.keiyakusaki_tantosha_email = this.getValue(this.items.keiyakusaki_tantosha_email?.value);
        this.keiyakusaki_yubinbango = this.getValue(this.items.keiyakusaki_yubinbango?.value);
        this.keiyakusaki_todofuken = this.getValue(this.items.keiyakusaki_todofuken?.value);
        this.keiyakusaki_shikugunchoson = this.getValue(this.items.keiyakusaki_shikugunchoson?.value);
        this.keiyakusaki_banchibuilding = this.getValue(this.items.keiyakusaki_banchibuilding?.value);
      }
      catch(e){
        console.error(e);
      }
    },
    goToKeiyaku() {
      this.$router.push('/userInfo/keiyaku');
    },
    goToSeikyuTanto() {
      this.$router.push('/userInfo/seikyu');
    }
  }
}
</script>

<style src="../css/UserInfoSectionTanto.css" scoped>
</style>

<style scoped>
  section {
    min-height: calc(100vh - 130px);
  }
</style>