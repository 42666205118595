<template>
  <div class="header sticky-top">
    <router-view name="header" v-bind:items="items" v-bind:saihanFlg="saihanFlg" />
    <router-view v-if="saihanFlg" name="saihanmenu"/>
    <router-view v-else/>
  </div>
  <router-view v-if="planErrFlg && this.$route.path.match('/plan')" name="error" v-bind:message="planErrMessage"/>
  <router-view v-else-if="seikyuErrFlg && this.$route.path.match('/seikyu')" name="error" v-bind:message="seikyuErrMessage"/>
  <router-view v-else name="contents" v-bind:items="items" @StartLoding="StartLoding" @EndLoding="EndLoding"/>
  <!-- <b-button type="button" class="primary" @click="Loding">Loding</b-button> -->
</template>

<script>
import moment from "moment"
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: 'UsageStatusSection',
  props: {
    items: Object,
    saihanFlg: Boolean,
  },
  data() {
    return {
      planErrFlg: false,
      planErrMessage: {
        tag: "契約情報",
        message_01: "適切な表示ができていません",
        message_02: "ヘルプの「お問い合わせ」よりご連絡ください"
      },
      seikyuErrFlg: false,
      seikyuErrMessage: {
        tag: "ご請求金額",
        message_01: "ただいまデータ準備中です",
        message_02: "ご請求実績情報が登録されるまで、しばらくお待ちください"
      },
    }
  },
  watch: {
    items: function() {
      this.setFlg();
    }
  },
  created(){
    if(this.items != null){
      this.setFlg();
    }    
  },
  mounted() {
    this.$router.replace('/usageStatus/plan');
  },
  methods: {
    Loding: async function(){
      this.$emit('StartLoding');
      await sleep(1000);
      this.$emit('EndLoding');
    },
    StartLoding(){
      this.$emit('StartLoding');
    },
    EndLoding(){
      this.$emit('EndLoding');
    },
    setFlg() {
      //プランオプション情報登録チェック
      var enabledServiceCount = 0
      if(this.items.plan_option_list == null || this.items.plan_option_list == undefined || this.items.plan_option_list.length == 0){
        this.planErrFlg = true;
      } else {
        for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
          if(this.items.plan_option_list[idx].service_seq.value > 0){
            if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
              var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
              var dateNow = moment()
              if(dateEnd.diff(dateNow, 'days') >= 0){
                //alert("diff")
                //利用期間中のサービスをカウント
                enabledServiceCount = enabledServiceCount + 1
              }
            } else {
              enabledServiceCount = enabledServiceCount + 1
            }
          }
        }
        if(enabledServiceCount == 0){
          this.planErrFlg = true;
        }
      }

      //請求先実績情報登録チェック
      if(this.items.seikyu_jisseki_list == null || this.items.seikyu_jisseki_list == undefined || this.items.seikyu_jisseki_list.length == 0){
        this.seikyuErrFlg = true;
      }
    }
  }
}
</script>
