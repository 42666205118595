<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-clearfix u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-1">
                <p style="text-align: center;" class="u-align-center u-custom-font u-text u-text-1" v-html="htmlText(mngMessage)"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";

export default {
  name: 'MaintenanceSectionCmp',
  props: {mngMessage: String}, 
  methods:{
    htmlText(msg){
      if(msg){
        return msg.replace(/\r?\n/g, '<br>')
      }
    },
    debug(){
      var dateTo = moment("20221114022128", "YYYYMMDDHHmmss")
      var dateFrom = moment()
      console.log("dateTo:" + dateTo.format("YYYY-MM-DD HH:mm:ss"))
      console.log("dateFrom:" + dateFrom.format("YYYY-MM-DD HH:mm:ss"))
    }
  }
}
</script>

<style src="../css/MaintenanceSection.css" scoped>
</style>

<style scoped>
  section {
    min-height: calc(100vh - 130px);
  }
</style>