<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
      <div class="u-clearfix u-expanded-width-sm u-expanded-width-xs u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-layout">
          <div class="u-layout-row">
            <div class="u-size-19">
              <div class="u-layout-col">
                <div class="u-align-left u-container-style u-hidden-sm u-hidden-xs u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
                  <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-1">
                    <div class="u-align-left u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-expanded-width u-group u-group-1">
                      <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-container-layout-2">
                        <h6 class="u-text u-text-default u-text-1">納入先リスト</h6>
                      </div>
                    </div>
                    <div class="u-align-center u-hidden-sm u-hidden-xs u-table u-table-responsive u-table-1">
                      <perfect-scrollbar>
                      <table class="u-table-entity">
                        <colgroup>
                          <col width="100%">
                        </colgroup>
                        <tbody v-for="list in lists" :key="list" class="u-table-body">
                          <tr style="height: 27px; white-space: nowrap;">
                            <td v-bind:class="{'link-active': list.isActive}" class="u-table-cell-hover u-table-cell u-table-cell-1" v-on:click="changeAppearDetalSeq(list.nonyusaki_seq)">{{ list.nonyusakimei }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="u-size-41">
              <div class="u-layout-col">
                <div class="u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-2">
                  <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-3">
                    <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-2">
                      <div class="u-container-layout u-container-layout-4">
                        <h6 style="word-break: break-all;" class="u-hidden-sm u-hidden-xs u-text u-text-default u-text-2">{{ this.nonyusakimei }}</h6>
                        <h6 style="word-break: break-all;" type="button" data-bs-toggle="dropdown" aria-expanded="false" class="u-hidden-lg u-hidden-md u-hidden-xl u-hidden-xxl u-text u-text-default u-text-3">{{ this.nonyusakimei_active }}</h6>
                        <ul class="dropdown-menu force-scroll" aria-labelledby="nonyuDropMenu" role="menu">
                        <li v-for="list in lists" :key="list">
                            <a style="word-break: break-all;" class="force-folding" v-on:click="changeAppearDetalSeq(list.nonyusaki_seq)" href="javascript:void(0)">{{list.nonyusakimei}}</a>
                        </li>                  
                        </ul>
                      </div>
                    </div>
                    <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-group u-group-3">
                      <div class="u-container-layout u-container-layout-5">
                        <div class="u-expanded-width-sm u-expanded-width-xs u-table u-table-responsive u-table-2">
                          <perfect-scrollbar>
                          <table class="u-table-entity">
                            <colgroup>
                              <col width="29.99%">
                              <col width="70.01%">
                            </colgroup>
                            <tbody class="u-table-body">
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-valign-top u-table-cell-12">納入先名</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-valign-top u-table-cell-13">{{ this.nonyusakimei }}</td>
                              </tr>
                              <tr style="height: 25px;">
                                <td class="u-table-cell u-table-valign-middle u-table-cell-14">納入先部署名</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-cell-15">{{ this.nonyusaki_bushomei }}</td>
                              </tr>
                              <tr style="height: 20px;">
                                <td class="u-table-cell u-table-valign-bottom u-table-cell-16"></td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-valign-bottom u-table-cell-17">{{ this.nonyusaki_tanto_furigana }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-valign-top u-table-cell-18">納入先担当者氏名</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-valign-top u-table-cell-19">{{ this.nonyusaki_tantoshamei }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-cell-20">メールアドレス</td>
                                <td style="word-break: break-all;" class="u-align-left-xs u-table-cell u-table-cell-21">{{ this.nonyusaki_tantosha_email }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-cell-22">郵便番号</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-cell-23">{{ this.nonyusaki_yubinbango }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-cell-24">都道府県</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-cell-25">{{ this.nonyusaki_todofuken }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-cell-26">市区群町村</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-cell-27">{{ this.nonyusaki_shikugunchoson }}</td>
                              </tr>
                              <tr style="height: 40px;">
                                <td class="u-table-cell u-table-cell-28">番地・ビル名</td>
                                <td style="word-break: break-all;" class="u-table-cell u-table-cell-29">{{ this.nonyusaki_banchibuilding }}</td>
                              </tr>
                            </tbody>
                          </table>
                          </perfect-scrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment"

export default {
  name: 'UserInfoSectionNonyuCmp',
  props: {items: Object},
  data(){
    return{
      blank : "　",
      nonyusakimei: this.blank,
      nonyusaki_bushomei: this.blank,
      nonyusaki_tanto_furigana: this.blank,
      nonyusaki_tantoshamei: this.blank,
      nonyusaki_tantosha_email: this.blank,
      nonyusaki_yubinbango: this.blank,
      nonyusaki_todofuken: this.blank,
      nonyusaki_shikugunchoson: this.blank,
      nonyusaki_banchibuilding: this.blank,
      list_format: {},
      lists: [
        { 
          id: 0,
          nonyusaki_code: this.blank,
          nonyusakimei: this.blank,
          nonyusaki_seq: this.blank,
          nonyusaki_bushomei: this.blank,
          nonyusaki_tanto_furigana: this.blank,
          nonyusaki_tantoshamei: this.blank,
          nonyusaki_tantosha_email: this.blank,
          nonyusaki_yubinbango: this.blank,
          nonyusaki_todofuken: this.blank,
          nonyusaki_shikugunchoson: this.blank,
          nonyusaki_banchibuilding: this.blank,
          isActive: false,
        }
      ],
      nonyusakimei_active: this.blank
    }
  },
  watch: {
    items: function() {
      this.setData();
      //this.changeAppearDetalSeq(this.lists[0].nonyusaki_seq)
      this.changeAppearDetalIdx(this.$route.query.index)
    }
  },
  created(){
    if(this.items != null){
      this.setData();
      //this.changeAppearDetal(this.lists[0].nonyusaki_seq)
      this.changeAppearDetalIdx(this.$route.query.index)
    }    
  },
  methods: {
    setData(){
      this.lists.length = 0 //配列初期化
      var bkNonyusakiSeq = "99999999";
      for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
        if(bkNonyusakiSeq != this.items.plan_option_list[idx].nonyusaki_seq.value && this.items.plan_option_list[idx].device_no.value >= 0){
          if(this.items.plan_option_list[idx].nonyusaki_seq.value == 0){
            continue;
          }
          this.list_format = {};
          this.list_format.id = -1;
          this.list_format.nonyusaki_code = this.items.plan_option_list[idx].nonyusaki_code.value;
          this.list_format.nonyusakimei = this.items.plan_option_list[idx].nonyusakimei.value;
          this.list_format.nonyusaki_seq = this.items.plan_option_list[idx].nonyusaki_seq.value;
          this.list_format.nonyusaki_bushomei = this.items.plan_option_list[idx].nonyusaki_bushomei.value;
          this.list_format.nonyusaki_tanto_furigana = this.items.plan_option_list[idx].nonyusaki_tanto_furigana.value;
          this.list_format.nonyusaki_tantoshamei = this.items.plan_option_list[idx].nonyusaki_tantoshamei.value;
          this.list_format.nonyusaki_tantosha_email = this.items.plan_option_list[idx].nonyusaki_tantosha_email.value;
          this.list_format.nonyusaki_yubinbango = this.items.plan_option_list[idx].nonyusaki_yubinbango.value;
          this.list_format.nonyusaki_todofuken = this.items.plan_option_list[idx].nonyusaki_todofuken.value;
          this.list_format.nonyusaki_shikugunchoson = this.items.plan_option_list[idx].nonyusaki_shikugunchoson.value;
          this.list_format.nonyusaki_banchibuilding = this.items.plan_option_list[idx].nonyusaki_banchibuilding.value;
          this.list_format.isActive = false;
          if(this.checkNonyusakiBranchEnabled(this.items.plan_option_list[idx].nonyusaki_seq.value) == true){
            this.lists.push(this.list_format)
          }
          bkNonyusakiSeq = this.items.plan_option_list[idx].nonyusaki_seq.value
        }
      }
    },
    changeAppearDetalSeq: function(nonyusaki_seq){
      for(var idx = 0; idx < this.lists.length; idx++){
        if(this.lists[idx].nonyusaki_seq == nonyusaki_seq){
          this.lists[idx].isActive = true
          this.nonyusakimei = this.lists[idx].nonyusakimei
          this.nonyusakimei_active = this.lists[idx].nonyusakimei
          this.nonyusaki_bushomei = this.lists[idx].nonyusaki_bushomei
          this.nonyusaki_tanto_furigana = this.lists[idx].nonyusaki_tanto_furigana
          this.nonyusaki_tantoshamei = this.lists[idx].nonyusaki_tantoshamei
          this.nonyusaki_tantosha_email = this.lists[idx].nonyusaki_tantosha_email
          this.nonyusaki_yubinbango = this.lists[idx].nonyusaki_yubinbango
          this.nonyusaki_todofuken = this.lists[idx].nonyusaki_todofuken
          this.nonyusaki_shikugunchoson = this.lists[idx].nonyusaki_shikugunchoson
          this.nonyusaki_banchibuilding = this.lists[idx].nonyusaki_banchibuilding
        } else {
          this.lists[idx].isActive = false
        }
      }
    },
    changeAppearDetalIdx: function(index){
      for(var idx = 0; idx < this.lists.length; idx++){
        this.lists[idx].isActive = false
      }
      this.nonyusakimei = this.lists[index].nonyusakimei
      this.nonyusakimei_active = this.lists[index].nonyusakimei
      this.nonyusaki_bushomei = this.lists[index].nonyusaki_bushomei
      this.nonyusaki_tanto_furigana = this.lists[index].nonyusaki_tanto_furigana
      this.nonyusaki_tantoshamei = this.lists[index].nonyusaki_tantoshamei
      this.nonyusaki_tantosha_email = this.lists[index].nonyusaki_tantosha_email
      this.nonyusaki_yubinbango = this.lists[index].nonyusaki_yubinbango
      this.nonyusaki_todofuken = this.lists[index].nonyusaki_todofuken
      this.nonyusaki_shikugunchoson = this.lists[index].nonyusaki_shikugunchoson
      this.nonyusaki_banchibuilding = this.lists[index].nonyusaki_banchibuilding
      this.lists[index].isActive = true
    },
    checkNonyusakiBranchEnabled: function(nonyusaki_seq){
      var enabledServiceCount = 0
      for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
        if(this.items.plan_option_list[idx].nonyusaki_seq.value == nonyusaki_seq){
          if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
            var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
            var dateNow = moment()
            if(dateEnd.diff(dateNow, 'days') >= 0){
              //alert("diff")
              //利用期間中のサービスをカウント
              enabledServiceCount = enabledServiceCount + 1
            }
          } else {
            enabledServiceCount = enabledServiceCount + 1
          }
        }
      }
      if(enabledServiceCount > 0){
        return true
      } else {
        return false
      }
    },
    addDummy(){
        //実データ部挿入
        this.list_format = {};
        this.list_format.id = -999;
        this.list_format.nonyusaki_code = "1Z999999";
        this.list_format.nonyusakimei = "dummy";
        this.list_format.nonyusaki_seq = "dummy";
        this.list_format.nonyusaki_bushomei = "dummy";
        this.list_format.nonyusaki_tanto_furigana = "dummy";
        this.list_format.nonyusaki_tantoshamei = "dummy";
        this.list_format.nonyusaki_tantosha_email = "dummy";
        this.list_format.nonyusaki_yubinbango = "dummy";
        this.list_format.nonyusaki_todofuken = "dummy";
        this.list_format.nonyusaki_shikugunchoson = "dummy";
        this.list_format.nonyusaki_banchibuilding = "dummy";

        this.lists.push(this.list_format)
    },
    myMethod() {
      //alert("UsageStatusSection()");
    },
  }
}
</script>

<style src="../css/UserInfoSectionNonyu.css" scoped>
</style>

<style scoped>

  section {
    min-height: calc(100vh - 130px);
  }

  .link-active {
    color: #42b983;
    text-decoration: none;
    pointer-events: none;
  }

  .u-text-3::after{
    position:relative;
    top: -3px;
    left: 5px;
    font-size: 0.5rem;
    content: "▼";
  }

  .u-border-1.u-border-nonyusakimei{
    border-top: none;
    border-bottom: none;
  }
  .u-border-1.u-border-tableLeft{
    border-top: none;
    border-bottom: none;
  }

  .u-table-cell-hover:hover {
    color: rgb(22, 97, 205) !important;
    cursor: pointer;
  }

  .ps {
    height: 450px;
  }

  .force-folding{
    font-size: 0.9rem;
    overflow-wrap: break-word;
    text-decoration: none;
    color:darkslategray
  }

  .force-scroll {
    overflow-y: scroll;
    width: 350px;
    max-height: 500px;
  }

</style>