<template>
  <loading v-model:active="isLoading" is-full-page="isFullPage" color="#42b983" opacity="0.5" lock-scroll="isLockScroll"/>
  <router-view v-bind:items="items" v-bind:saihanFlg="saihanFlg" v-bind:maintenanceFlg="maintenanceFlg" v-bind:mngMessage="mngMessage" @StartLoding="StartLoding" @EndLoding="EndLoding" />
</template>

<script>
import crypto from 'crypto-js'
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const params = new URLSearchParams(window.location.search);

export default {
  name: 'App',
  data(){
    return{
      version: "1.0.0",
      items: null,
      saihanFlg: false,
      maintenanceFlg: false,
      loginTimeoutFlg: false,
      sessionTimeoutFlg: false,
      mngMessage: `ご契約者様マイページにアクセスできませんでした
      メンバーサイトに戻り、リンクから再度アクセスしてください`,
      loginTimeoutMsg: `予期しない操作が行われました
      メンバーサイトに戻り、リンクから再度アクセスしてください`,
      sessionTimeoutMsg: `一定期間操作がなかったため、画面を自動的に閉じます
      メンバーサイトに戻り、リンクから再度アクセスしてください`,
      isLoading: true,
      isFullPage: true,
      isLockScroll: false,
      aesKey: "1927BE6E58A0E5C8051CC3E211C69F504A7862DFA328170AD9B0762EACF7E582",
      apiUrl: "https://api.env.my-plan.splats.jp/auth/keiyaku",
      apiUrl_Mng: "https://api.mng.env.my-plan.splats.jp/users/keiyaku",
      devTenantId: "eee343ae-bac9-489c-b386-07f41d76bd9d",
      idToken: "",
      env: "null",
      timer: 0,
      timerId: 0,
      timerLimit: 30,
      TIMEOUT_DURATION: 60000
    }
  },
  components: {
    Loading
  },
  watch: {
    timer: function(){
      //console.log("timer:"+ this.timer)
      if(this.timer == this.timerLimit){
        clearInterval(this.timerId)
        this.sessionTimeoutFlg = true
        this.maintenanceFlg = true
      }
    },
    loginTimeoutFlg: function(){
      this.mngMessage = this.loginTimeoutMsg
      console.log("login timeout")
    },
    sessionTimeoutFlg: function(){
      this.mngMessage = this.sessionTimeoutMsg
      console.log("session timeout")
    }
  },
  mounted() {
    document.addEventListener('click', this.onClick)
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClick)
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('scroll', this.onScroll)
  },
  async created() {
    this.isLoading = true;

    sessionStorage.setItem('ver', this.version)

    if(window.location.href.match("dev")){
      this.env = "dev"
    } else if(window.location.href.match("ver")) {
      this.env = "ver"
    } else {
      this.env = "prd"
    }
    sessionStorage.setItem('env', this.env)

    moment.tz.setDefault('Asia/Tokyo'); // タイムゾーンをJSTに設定

    if(window.location.href.indexOf('mng.') !== -1) {
      //管理者用
      sessionStorage.setItem('mode', "manage")
      if(params.get("tenantId") == null){
        //tenantIdがない、メンテナンスページへ
        this.maintenanceFlg = true
      } else {
        try {
          await this.getData_Mng(params.get("tenantId"));
          // 再販ユーザー判定
          if(this.items.saihan_flag == "1"){
            this.saihanFlg = true
          }
          // セッションタイマースタート
          this.setTimer()
        }
        catch(e){
          console.error(e);
          this.maintenanceFlg = true
        }
      }
    } else if(window.location.href.indexOf('localhost:') !== -1) {
      //ローカル検証用
      this.env = "dev"
      sessionStorage.setItem('env', this.env)
      sessionStorage.setItem('mode', "local")
      try {
        var dateFrom = moment()
        console.log("dateFrom:" + dateFrom.format("YYYY-MM-DD HH:mm:ss"))
        await this.getData_Mng(this.devTenantId);
        // 再販ユーザー判定
        if(this.items.saihan_flag == "1"){
          this.saihanFlg = true
        }
        // セッションタイマースタート
        this.setTimer()
      }
      catch(e){
        console.error(e);
        this.maintenanceFlg = true
      }
    } else {
      sessionStorage.setItem('mode', "normal")
      await this.checkAuthentification()
      await sleep(500);
      try {
        await this.getData();
        // 再販ユーザー判定
        if(this.items.saihan_flag == "1"){
          this.saihanFlg = true
        }
        // セッションタイマースタート
        this.setTimer()
      }
      catch(e){
        console.error(e);
        this.maintenanceFlg = true
      }
    }

    await sleep(500);
    //console.log(this.items)
    
    this.isLoading = false;
  },
  methods:{
    checkAuthentification: async function(){
      if(params.get("idToken") == null){
        //tokenがない、メンテナンスページへ
        this.maintenanceFlg = true
      } else {
        //tokenを解析
        const decrypted = crypto.AES.decrypt(decodeURIComponent(params.get("idToken")), this.aesKey);

        //現在時刻を取得、暗号化タイムスタンプと比較
        var dateTo = moment(decrypted.toString(crypto.enc.Utf8).substr(0, 14), "YYYYMMDDHHmmss")
        console.log("dateTo:" + dateTo.format("YYYY-MM-DD HH:mm:ss"))
        var dateFrom = moment()
        console.log("dateFrom:" + dateFrom.format("YYYY-MM-DD HH:mm:ss"))
        if(Math.abs(dateFrom.diff(dateTo, 'second')) > 5){
          this.loginTimeoutFlg = true
          this.maintenanceFlg = true
          console.log("token timeout diff:" + dateFrom.diff(dateTo, 'second'))
        }

        this.idToken = decrypted.toString(crypto.enc.Utf8).substr(14, decrypted.toString(crypto.enc.Utf8).length)
        sessionStorage.setItem('idToken', this.idToken)
      }
    },
    getData: async function(){
      await axios(
        {
          method:'GET',
          url: this.apiUrl.replace("env", sessionStorage.getItem("env")).replace("prd.", ""),
          headers: {
            'Authorization': 'Bearer '+ this.idToken,
          },
        }
      ).then(response => this.items = response.data)
    },
    getData_Mng: async function(tenantId){
      await axios(
        {
          method:'GET',
          url: this.apiUrl_Mng.replace("env", sessionStorage.getItem("env")).replace("prd.", "") + "/" + tenantId,
        }
      ).then(response => this.items = response.data)
    },
    setTimer(){
      // セッションタイマースタート
      console.log("setTimer")
      this.timerId = setInterval(() => {
        this.timer++
      }, this.TIMEOUT_DURATION)
    },
    resetTimer(){
      this.timer = 0
    },
    onClick(){
      // ボタンがクリックされた場合の処理
      this.resetTimer()
    },
    onKeyDown(){
      // キーボードが押された場合の処理
      this.resetTimer()
    },
    onScroll(){
      // スクロールされた場合の処理
      this.resetTimer()
    },
    getiOSVersion(){
      return parseFloat(
          ("" + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,""])[1]).replace("undefined", "3_2").replace("_", ".").replace("_", "")) || false;
    },
    StartLoding(){
      this.isLoading = true;
    },
    EndLoding(){
      this.isLoading = false;
    }
  }
}
</script>

<style src="./css/nicepage.css">
</style>

<style>
#app {
  font-family: "Noto Sans JP", kozuka-gothic-pro, century-gothic, sans-serif -apple-system, BlinkMacSystemFont, "Original Yu Gothic", 游ゴシック, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.router-link-passive {
  color: #2c3e50;
  text-decoration: none;
}

.router-link-passive:hover{
  text-decoration: none;
}

.router-link-active {
  color: #42b983;
  text-decoration: none;
  pointer-events: none;
}

.router-link-active:hover{
  color: #42b983;
  text-decoration: none;
}

.header {
  filter: drop-shadow(3px 3px 3px rgba(228, 222, 222, 0.6));
}

</style>

