<template>
  <section class="u-clearfix u-white u-section-1" id="menuSection">
    <div class="u-clearfix u-sheet u-sheet-1"></div>
  </section>
</template>

<script>
export default {
  name: 'MaintenanceSectionMenuCmp'
}
</script>

<style src="../css/MaintenanceSection.css" scoped>
</style>