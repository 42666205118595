<template>
  <section class="u-clearfix u-white u-section-1" id="menuSection">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-align-center u-table u-table-responsive u-table-1">
        <table class="u-table-entity">
          <colgroup>
            <col width="100%">
          </colgroup>
          <tbody class="u-table-body">
            <tr style="height: 48px;">
              <td class="u-align-center u-table-cell u-table-valign-middle u-table-cell-1"><router-link to="/usageStatus/plan" exact class="router-link-passive" active-class="router-link-active">契約情報</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UsageStatusSectionMenuSaihanCmp'
}
</script>

<style src="../css/UsageStatusSectionSaihan.css" scoped>
</style>