<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-sheet-1">
      <div class="u-clearfix u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-container-layout-1">
                <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
                  <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-container-layout-2">
                    <h6 class="u-text u-text-default u-text-1">{{ this.message.tag }}</h6>
                  </div>
                </div>
                <div class="u-container-style u-group u-shape-rectangle u-group-2">
                  <div class="u-container-layout u-container-layout-3">
                    <h3 class="u-align-center u-text u-text-default u-text-grey-25 u-text-2 u-text-escaped">{{ this.message.message_01 }}</h3>
                    <h5 class="u-align-center u-text u-text-default u-text-3 u-text-escaped">{{ this.message.message_02 }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'UsageStatusSectionErrorCmp',
  props: {message: Object}, 
}
</script>

<style src="../css/UsageStatusSectionError.css" scoped>
</style>

<style scoped>

  section {
    min-height: calc(100vh - 130px);
  }

  .u-text-escaped{
    white-space: pre-wrap;
  }

</style>