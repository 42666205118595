import { createRouter, createWebHashHistory } from 'vue-router'
import RootView from '../views/RootView.vue'

import HeaderSectionCmp from '../components/HeaderSectionCmp.vue'

import UsageStatusSection from '../views/UsageStatusSection.vue'
import UsageStatusSectionMenuCmp from '../components/UsageStatusSectionMenuCmp.vue'
import UsageStatusSectionMenuSaihanCmp from '../components/UsageStatusSectionMenuSaihanCmp.vue'
import UsageStatusSectionPlanCmp from '../components/UsageStatusSectionPlanCmp.vue'
import UsageStatusSectionSeikyuCmp from '../components/UsageStatusSectionSeikyuCmp.vue'
import UsageStatusSectionErrorCmp from '../components/UsageStatusSectionErrorCmp.vue'

import UserInfoSection from '../views/UserInfoSection.vue'
import UserInfoSectionMenuCmp from '../components/UserInfoSectionMenuCmp.vue'
import UserInfoSectionMenuSaihanCmp from '../components/UserInfoSectionMenuSaihanCmp.vue'
import UserInfoSectionKeiyakuCmp from '../components/UserInfoSectionKeiyakuCmp.vue'
import UserInfoSectionTantoCmp from '../components/UserInfoSectionTantoCmp.vue'
import UserInfoSectionSeikyuTantoCmp from '../components/UserInfoSectionSeikyuTantoCmp.vue'
import UserInfoSectionNonyuCmp from '../components/UserInfoSectionNonyuCmp.vue'
import UserInfoSectionErrorCmp from '../components/UserInfoSectionErrorCmp.vue'

import MaintenanceSection from '../views/MaintenanceSection.vue'

const routes = [
  {
    path: '/',
    name: 'root',
    component: RootView,
    children: [
      {
        path: 'maintenance',
        component: MaintenanceSection,
      },
      {
        path: 'usageStatus',
        component: UsageStatusSection,
        children: [
          {
            path: 'plan',
            components: {
              default: UsageStatusSectionMenuCmp,
              saihanmenu: UsageStatusSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UsageStatusSectionPlanCmp,
              error: UsageStatusSectionErrorCmp
            }
          },
          {
            path: 'seikyu',
            components: {
              default: UsageStatusSectionMenuCmp,
              saihanmenu: UsageStatusSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UsageStatusSectionSeikyuCmp,
              error: UsageStatusSectionErrorCmp
            }
          },
        ]
      },
      {
        path: 'userInfo',
        component: UserInfoSection,
        children: [
          {
            path: 'keiyaku',
            components: {
              default: UserInfoSectionMenuCmp,
              saihanmenu: UserInfoSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UserInfoSectionKeiyakuCmp,
              error: UserInfoSectionErrorCmp
            }
          },
          {
            path: 'tanto',
            components: {
              default: UserInfoSectionMenuCmp,
              saihanmenu: UserInfoSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UserInfoSectionTantoCmp,
              error: UserInfoSectionErrorCmp
            }
          },
          {
            path: 'seikyu',
            components: {
              default: UserInfoSectionMenuCmp,
              saihanmenu: UserInfoSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UserInfoSectionSeikyuTantoCmp,
              error: UserInfoSectionErrorCmp
            }
          },
          {
            path: 'nonyu',
            components: {
              default: UserInfoSectionMenuCmp,
              saihanmenu: UserInfoSectionMenuSaihanCmp,
              header: HeaderSectionCmp,
              contents: UserInfoSectionNonyuCmp,
              error: UserInfoSectionErrorCmp
            },
            name: 'Nonyu'
          },
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
