<template>
  <router-view v-bind:items="items" v-bind:saihanFlg="saihanFlg" v-bind:maintenanceFlg="maintenanceFlg" v-bind:mngMessage="mngMessage" @StartLoding="StartLoding" @EndLoding="EndLoding" />
  <!-- <Modal msg="モーダルの内容" /> -->
</template>

<script>
/* import Modal from "../components/ModalSectionCmp"; */

export default {
  name: 'RootView',
  /* components: {
    Modal
  }, */
  props: {
    items: Object,
    saihanFlg: Boolean,
    maintenanceFlg: Boolean,
    mngMessage: String
  },
  watch: {
    maintenanceFlg: function(){
      this.$router.replace('/maintenance');
    }
  },
  mounted(){
    //初期画面 契約プラン照会へ遷移
    if(this.maintenanceFlg){
      this.$router.replace('/maintenance');
    } else {
      this.$router.replace('/usageStatus/plan');
    }
  },
  methods: {
    StartLoding(){
      this.$emit('StartLoding');
    },
    EndLoding(){
      this.$emit('EndLoding');
    }
  },
}
</script>
