<template>
  <div class="header sticky-top">
    <router-view name="header" v-bind:items="items" v-bind:saihanFlg="saihanFlg" />
    <router-view v-if="saihanFlg" name="saihanmenu"/>
    <router-view v-else/>
  </div>
  <!-- <router-view v-if="keiyakuErrFlg && this.$route.path.match('/keiyaku')" name="error" v-bind:message="keiyakuErrMessage"/> -->
  <router-view v-if="tantoErrFlg && this.$route.path.match('/tanto')" name="error" v-bind:message="tantoErrMessage"/>
  <router-view v-else-if="seikyuErrFlg && this.$route.path.match('/seikyu')" name="error" v-bind:message="seikyuErrMessage"/>
  <router-view v-else-if="nonyuErrFlg && this.$route.path.match('/nonyu')" name="error" v-bind:message="nonyuErrMessage"/>
  <router-view v-else name="contents" v-bind:items="items" @StartLoding="StartLoding" @EndLoding="EndLoding"/>
</template>

<script>
import moment from "moment"

export default {
  name: 'UserInfoSection',
  props: {
    items: Object,
    saihanFlg: Boolean
  },
  data() {
    return {
      keiyakuErrFlg: false,
      keiyakuErrMessage: {
        tag: "ご契約先情報",
        message_01: "ただいまメンテナンス中です",
        message_02: "ご契約先情報が登録されるまで、しばらくお待ちください"
      },
      tantoErrFlg: false,
      tantoErrMessage: {
        tag: "契約者情報",
        message_01: "ただいまデータ準備中です",
        message_02: "契約者情報が登録されるまで、しばらくお待ちください"
      },
      seikyuErrFlg: false,
      seikyuErrMessage: {
        tag: "請求先情報",
        message_01: "ただいまデータ準備中です",
        message_02: "請求先情報が登録されるまで、しばらくお待ちください"
      },
      nonyuErrFlg: false,
      nonyuErrMessage: {
        tag: "納入先情報",
        message_01: "適切な表示ができていません",
        message_02: "ヘルプの「お問い合わせ」よりご連絡ください"
      },
    }
  },
  watch: {
    items: function() {
      this.setFlg();
    }
  },
  created(){
    if(this.items != null){
      this.setFlg();
    }    
  },
  mounted(){
    if(this.$route.query.index != undefined){
      this.$router.replace({name: 'Nonyu', query: { index: this.$route.params.index}});
      //this.$router.push({name: 'Nonyu', params: { index: this.$route.params.index}});
    } else {
      this.$router.replace('/userInfo/tanto');
    }
  },
  methods: {
    setFlg() {
      //契約担当者情報登録チェック
      if(this.items.keiyakusaki_code.value == null || this.items.keiyakusaki_code.value == undefined){
        this.tantoErrFlg = true;
      }
      //請求先情報登録チェック
      if(this.items.seikyusaki_code.value == null || this.items.seikyusaki_code.value == undefined){
        this.seikyuErrFlg = true;
      }
      //納入先情報登録チェック
      var enabledServiceCount = 0
      if(this.items.plan_option_list == null || this.items.plan_option_list == undefined || this.items.plan_option_list.length == 0){
        this.nonyuErrFlg = true;
      } else {
        for(var idx = 0; idx < this.items.plan_option_list.length; idx++){
          if(this.items.plan_option_list[idx].nonyusaki_seq.value > 0 && this.items.plan_option_list[idx].service_seq.value > 0){
            if(this.items.plan_option_list[idx].riyo_syuryobi.value.length == 8){
              var dateEnd = moment(this.items.plan_option_list[idx].riyo_syuryobi.value, "YYYYMMDDHHmmss")
              var dateNow = moment()
              if(dateEnd.diff(dateNow, 'days') >= 0){
                //利用期間中のサービスをカウント
                enabledServiceCount = enabledServiceCount + 1
              }
            } else {
              enabledServiceCount = enabledServiceCount + 1
            }
          }
        }
        if(enabledServiceCount == 0){
          this.nonyuErrFlg = true;
        }
      }
    }
  }
}
</script>

<style src="../css/UserInfoSection.css" scoped>
</style>