<template>
  <header class="headerSection u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-header u-white" id="headerSection" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
    <div class="u-clearfix u-sheet u-sheet-1">
        <img class="u-align-left u-hidden-lg u-hidden-md u-hidden-xl u-hidden-xxl u-image u-image-default u-preserve-proportions u-image-1" src="@/assets/images/SPLATSLOGOBLUEGREENBLUE.svg" alt="" data-image-width="256" data-image-height="256">
        <p v-show="maintenanceFlg==false" type="button" id="mainMenu" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle u-align-center-lg u-align-center-md u-align-center-xl u-align-center-xxl u-align-left-sm u-align-left-xs u-custom-font u-hidden-lg u-hidden-md u-hidden-xl u-hidden-xxl u-text u-text-1" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">{{ this.menuCurrent.value }}</p>
        <ul class="dropdown-menu" aria-labelledby="mainMenu" role="menu">
          <li v-for="menu in menus" :key="menu">
              <a class="dropdown-item" v-on:click="mainmenuDropDownClick(menu)" href="javascript:void(0)">{{menu.value}}</a>
          </li>
        </ul>
        <div class="u-align-center u-hidden-sm u-hidden-xs u-table u-table-responsive u-table-1">
          <table class="u-table-entity">
            <colgroup>
              <col width="42.31%">
              <col width="15.38%">
              <col width="42.31%">
            </colgroup>
            <tbody class="u-table-body">
              <tr style="height: 45px;">
                <td v-show="maintenanceFlg==false" class="u-align-center u-table-cell u-table-valign-middle u-table-cell-1"><router-link to="/usageStatus" class="router-link-passive" active-class="router-link-active">ご利用状況</router-link></td>
                <td class="u-table-cell u-table-cell-2"></td>
                <td v-show="maintenanceFlg==false" class="u-align-center u-table-cell u-table-cell-3"><router-link to="/userInfo" class="router-link-passive" active-class="router-link-active">ご登録情報</router-link></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="u-container-style u-group u-shape-rectangle u-group-1">
          <div class="u-container-layout u-container-layout-1">
            <span type="button" data-bs-toggle="dropdown" aria-expanded="false" class="u-file-icon u-icon u-icon-1"><helpIcon :size="31" style="color: gray;"/></span>
            <ul class="dropdown-menu">
              <li v-for="helpMenu in helpMenus" :key="helpMenu">
                  <a v-if="helpMenu.visible && helpMenu.href==true" class="dropdown-item" v-on:click="helpmenuDropDownClick(helpMenu.toUrl)" href="javascript:void(0)">{{helpMenu.value}}</a>
                  <a v-if="helpMenu.visible && helpMenu.href==false" class="dropdown-item" v-on:click="getPresignedUrl">{{helpMenu.value}}</a>
              </li>
            </ul>
            <div type="button" data-bs-toggle="dropdown" aria-expanded="false" >
              <p class="u-custom-font u-text u-text-default u-text-grey-40 u-text-2">ヘルプ<span style="font-weight: 400;"></span></p>
            </div>
            <ul class="dropdown-menu">
              <li v-for="helpMenu in helpMenus" :key="helpMenu">
                  <a v-if="helpMenu.visible && helpMenu.href==true" class="dropdown-item" v-on:click="helpmenuDropDownClick(helpMenu.toUrl)" href="javascript:void(0)">{{helpMenu.value}}</a>
                  <a v-if="helpMenu.visible && helpMenu.href==false" class="dropdown-item" v-on:click="getPresignedUrl">{{helpMenu.value}}</a>
              </li>
            </ul>
          </div>
        </div>
        <a class="u-align-left u-hidden-sm u-hidden-xs u-image u-logo u-image-2" data-image-width="771" data-image-height="198">
          <img src="@/assets/images/SPLATS_LOGOLOGOTYPE_H_RGB_ver2.svg" class="u-logo-image u-logo-image-1" data-image-width="80">
        </a>
        <h1 class="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-align-left-xl u-align-left-xxl u-custom-font u-hidden-sm u-hidden-xs u-text u-text-default-lg u-text-default-md u-text-default-xl u-text-default-xxl u-text-3">ご契約者様マイページ</h1>
      </div>
  </header>
</template>

<script>
import helpIcon from "vue-material-design-icons/HelpCircleOutline.vue";
import axios from 'axios'

export default {
  name: 'HeaderSectionCmp',
  components: {
    helpIcon
  },
  props: {
    items: Object,
    saihanFlg: Boolean,
    maintenanceFlg: Boolean,
  },
  data(){
    return{
      keiyaku_no: "　",
      menuCurrent: {value: "　", toRouter: "　"},
      menus: [
        {value: "ご利用状況", toRouter: "/usageStatus"},
        {value: "ご登録情報", toRouter: "/userInfo"}
      ],
      helpMenus: [
        {value: "オンラインヘルプ", href: true, toUrl: "https://tayori.com/q/splats-onlinehelp", keiyakuParam: false, maintenanceFlg: true, saihanFlag: true, visible: true},
        {value: "お問い合わせ", href: true, toUrl: "https://form.kumahira.co.jp/m/splats_m_form", keiyakuParam: true, maintenanceFlg: true, saihanFlag: true, visible: true},
        {value: "利用規約", href: false, toUrl: "https://cloudfront.net/splats_terms_of_service.pdf", keiyakuParam: false, maintenanceFlg: false, saihanFlag: false, visible: true}
      ],
      presignedUrl: String,
      apiUrl: "https://api.env.my-plan.splats.jp/file/terms",
    }
  },
  watch: {
    maintenanceFlg: function() {
      this.helpmenuFilter()
    },
    saihanFlg: function() {
      this.helpmenuFilter()
    },
    items(){
      this.setData
      this.helpmenuFilter()
    }
  },
  created() {
    this.helpmenuFilter()
  },
  mounted() {
    for(var idx = 0; idx < this.menus.length; idx++){
      if(this.$route.path.match(this.menus[idx].toRouter)){
        this.menuCurrent = this.menus[idx]
      }
    }
  },
  methods: {
    goToHome() {
      this.$router.push('/#');
    },
    goToUserInfo() {
      this.$router.push('/userInfo/keiyaku');
    },
    goToUsageStatus() {
      this.$router.push('/usageStatus/plan');
    },
    dropDownCurrentSet() {

    },
    mainmenuDropDownClick: function(setMenu) {
      if(this.menuCurrent != setMenu){
        this.menuCurrent = setMenu
        this.$router.push(setMenu.toRouter);
      }
    },
    helpmenuDropDownClick: function(setUrl) {
      window.open(setUrl);
    },
    setData(){
      this.keiyaku_no = this.items.keiyaku_no;
    },
    helpmenuFilter() {
      if(this.maintenanceFlg){
        for(var idx = 0; idx < this.helpMenus.length; idx++){
          if(this.helpMenus[idx].maintenanceFlg == false){
            this.helpMenus[idx].visible = false
          }
        }
      }

      if(this.saihanFlg){
        for(idx = 0; idx < this.helpMenus.length; idx++){
          if(this.helpMenus[idx].saihanFlag == false){
            this.helpMenus[idx].visible = false
          }
        }
      }
      if(this.items != null){
        for(idx = 0; idx < this.helpMenus.length; idx++){
          if(this.helpMenus[idx].keiyakuParam == true){
            if(this.helpMenus[idx].toUrl.match("/?e_185=") == null){
              this.helpMenus[idx].toUrl = this.helpMenus[idx].toUrl + "/?e_185=" + this.items.keiyaku_no
            }            
          }
        }
      }
    },
    getPresignedUrl: async function(){
      try {
        await axios(
          {
            method:'GET',
            url: this.apiUrl.replace("env", sessionStorage.getItem("env")).replace("prd.", ""),
          }
        ).then(response => this.presignedUrl = response.data)
        //alert(this.presignedUrl)
        //window.open(this.presignedUrl, '_blank');
        setTimeout(() => {
            window.open(this.presignedUrl, '_blank');
        })
      }
      catch(e){
        console.error(e);
      }
    },
  }
} 
</script>

<style src="../css/UserInfoSection.css" scoped>
</style>

<style scoped>
.scroller {
  height: 100%;
}

.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}

</style>
