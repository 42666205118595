<template>
  <section class="u-clearfix u-custom-color-1 u-section-2" id="blockSection">
    <div class="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-sheet-1">
      <div class="u-clearfix u-expanded-width-xs u-gutter-18 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-radius-5 u-shape-round u-size-60 u-white u-layout-cell-1">
              <div class="u-border-1 u-border-grey-10 u-container-layout u-valign-bottom-lg u-valign-bottom-md u-valign-bottom-xl u-valign-bottom-xs u-valign-bottom-xxl u-container-layout-1">
                <div class="u-border-1 u-border-grey-5 u-border-no-left u-border-no-right u-border-no-top u-container-style u-group u-group-1">
                  <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-middle-xxl u-container-layout-2">
                    <h6 class="u-text u-text-default u-text-1">ご契約先情報</h6>
                  </div>
                </div>
                <perfect-scrollbar>
                <div class="u-container-style u-group u-shape-rectangle u-group-2">
                  <div class="u-container-layout u-container-layout-3">
                    <p class="u-custom-font u-text u-text-body-color u-text-2">{{ this.keiyakusakimei_furigana }}</p>
                    <p class="u-custom-font u-text u-text-body-color u-text-3">会社名<span style="font-weight: 700;">
                        <span style="font-weight: 400;"></span>
                      </span>
                    </p>
                    <p class="u-custom-font u-text u-text-body-color u-text-4">{{ this.keiyakusakimei }}</p>
                    <p class="u-custom-font u-text u-text-body-color u-text-5" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
                      <span style="font-weight: 700;">代表者名 </span>
                    </p>
                    <p class="u-custom-font u-text u-text-body-color u-text-6">{{ this.daihyoshamei }}</p>
                    <p class="u-custom-font u-text u-text-body-color u-text-7">{{ this.keiyakusya_furigana }}</p>
                    <p class="u-custom-font u-text u-text-body-color u-text-8" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
                      <span style="font-weight: 700;">責任者</span>
                    </p>
                    <p class="u-custom-font u-text u-text-body-color u-text-9">{{ this.keiyakusyamei }}</p>
                  </div>
                </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserInfoSectionKeiyakuCmp',
  props: {items: Object},
  data(){
    return{
      blank : "　",
      keiyakusakimei_furigana: this.blank,
      keiyakusakimei: this.blank,
      daihyoshamei: this.blank,
      keiyakusya_furigana: this.blank,
      keiyakusyamei: this.blank
    }
  },
  watch: {
    items: function() {
      this.setData();
    }
  },
  created(){
    if(this.items != null){
      this.setData();
    }    
  },
  methods: {
    getValue : function(item){
      return item == undefined || item.length == 0 ? this.blank : item;
    },
    setData(){
      try {
        this.keiyakusakimei_furigana = this.getValue(this.items.keiyakusakimei_furigana?.value);
        this.keiyakusakimei = this.getValue(this.items.keiyakusakimei?.value);
        this.daihyoshamei = this.getValue(this.items.daihyoshamei?.value);
        this.keiyakusya_furigana = this.getValue(this.items.keiyakusya_furigana?.value);
        this.keiyakusyamei = this.getValue(this.items.keiyakusyamei?.value);
      }
      catch(e){
        console.error(e);
      }
    },
    goToTanto() {
      this.$router.push('/userInfo/tanto');
    },
    goToSeikyuTanto() {
      this.$router.push('/userInfo/seikyu');
    }
  }
}
</script>

<style src="../css/UserInfoSectionKeiyaku.css" scoped>
</style>

<style scoped>
  section {
    min-height: calc(100vh - 130px);
  }
</style>